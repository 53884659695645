import React, { useRef, useState } from 'react'
import './style.scss'
import { useMutation, useQuery } from '@apollo/client'
import { GetLocations, GetMachines } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'
import { CreateLocation, CreateMachine, RemoveLocation, RemoveMachine, UpdateLocation, UpdateMachine } from '../../graphql/mutations'
import TableInput from '../../components/TableInput'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import MinusIcon from '../../components/icons/minus'
import PlusIcon from '../../components/icons/plus'
import Modal from '../../components/Modal'
import Input from '../../components/Input'
import FilterTable from '../../components/FilterTable'
import SelectInput from '../../components/SelectInput'
import { MachineCodes } from '../../util/const'

const includeFields = [
    {
        value: 'ORDER_NO',
        label: 'Järjekord',
    },
    {
        value: 'LABEL',
        label: 'Nimetus',
    },
    {
        value: 'location',
        label: 'Asukoht',
    },
    {
        value: 'PERFORMANCE',
        label: 'Jõudlus',
    },
    {
        value: 'MIN_SIZE',
        label: 'Min. Mõõt',
    },
    {
        value: 'MAX_SIZE',
        label: 'Max. Mõõt',
    },
    {
        value: 'CODE',
        label: 'Tüüp',
    },
    {
        value: 'SCHEDULE',
        label: 'Graafik',
    }
]

const initialState = {
    LABEL: '',
    CODE: '',
    LOCATION_ID: null,
    ORDER_NO: 0,
    PERFORMANCE: 0,
    MIN_SIZE: 0,
    MAX_SIZE: 0,
}

const MachinesScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const [selectedRow, setSelectedRow] = useState()
    const [showModal, setShowModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [newItem, setNewItem] = useState(initialState)
    const [locations, setLocations] = useState([])

    useQuery(GetLocations, {
        variables: {
            page: 0,
            limit: 100,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const { getLocations } = data
            setLocations(getLocations?.rows || [])
        }
    })

    const [updateItem] = useMutation(UpdateMachine)
    const [createItem] = useMutation(CreateMachine)
    const [removeItem] = useMutation(RemoveMachine)

    const addItem = async () => {
        console.log('validating', validateFields())
        if (!validateFields()) return

        try {
            console.log('creating item', newItem)
            const { data } = await createItem({
                variables: {
                    data: {
                        ...newItem,
                        LOCATION_ID: parseInt(newItem.LOCATION_ID, 10),
                        ORDER_NO: parseInt(newItem.ORDER_NO, 10),
                        PERFORMANCE: newItem?.PERFORMANCE ? parseFloat(newItem.PERFORMANCE) : undefined,
                        MIN_SIZE: newItem?.MIN_SIZE ? parseFloat(newItem.MIN_SIZE) : undefined,
                        MAX_SIZE: newItem?.MAX_SIZE ? parseFloat(newItem.MAX_SIZE) : undefined,
                    },
                },
            })

            if (data?.createMachine === 'Success') {
                setShowModal(false)
                setNewItem({ ...initialState })
                tableRef.current?.refresh()
            }
        } catch (err) {
            console.log('Error creating item', err)
        }
    }

    const deleteItem = async () => {
        if (!selectedRow) return

        try {
            const { data } = await removeItem({
                variables: {
                    id: parseInt(selectedRow.ID, 10),
                },
            })

            if (data?.removeMachine === 'Success') {
                setSelectedRow(null)
                tableRef.current?.refresh()
            }
        } catch (err) {
            console.log('Error deleting item', err)
        }
    }

    const setField = (field, value) => {
        setNewItem({...newItem, [field]: value })
    }

    const updateProperty = async (item, field, value) => {
        if (!item) return

        let finalValue = ['MIN_SIZE', 'MAX_SIZE', 'PERFORMANCE', 'ORDER_NO'].includes(field) ? parseFloat(value) : value

        try {
            await updateItem({
                variables: {
                    id: parseInt(item.ID, 10),
                    data: {
                        [field]: finalValue,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty:', err)
        }
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'location':
                return row.location?.LABEL
            case 'CODE':
                return (
                    <div
                        className='machine-code-column'
                        onClick={() => {
                            setSelectedRow(row)
                            setShowEditModal(true)
                        }}
                    >
                        { t(row[field]) }
                    </div>
                )
            case 'SCHEDULE':
                return (
                    <Button
                        label={t('Graafik')}
                        onClick={() => window.open(`/machines/${row.ID}`)}
                    />
                )
            default:
                return <TableInput
                    initialValue={row[field] === 0 ? '0' : row[field]}
                    label={field}
                    fieldUpdated={(field, value) => {
                        updateProperty(row, field, value)
                    }}
                />
        }
    }

    const handleRowClick = async (row) => {
        setSelectedRow(row)
    }

    const validateFields = () => {
        if (!newItem.LABEL || !newItem.CODE || !newItem.LOCATION_ID) return false
        return true
    }

    return (
        <div className='machines-screen'>
            <div className='inner'>
                <div className='inner-title'>
                    <h2>{t('Masinad')}</h2>
                    <div className='inner-title--actions'>
                        <IconButton
                            label={'Lisa'}
                            icon={<PlusIcon />}
                            onClick={() => setShowModal(true)}
                        />
                        <IconButton
                            label={'Kustuta'}
                            icon={<MinusIcon />}
                            disabled={!selectedRow}
                            onClick={deleteItem}
                        />
                    </div>
                </div>
                <FilterTable
                    ref={tableRef}
                    query={GetMachines}
                    queryKey={'getMachines'}
                    fieldConditions={fieldConditions}
                    includeFields={includeFields}
                    initialOrderBy={'ID'}
                    onRowClick={handleRowClick}
                    activeRows={selectedRow ? [selectedRow] : []}
                />
            </div>
            <Modal
                title={t('Uus masin')}
                show={showModal}
                close={() => setShowModal(false)}
            >
                <h5>{t('Lisa uus masin')}</h5>
                <Input
                    label={`${t('Nimetus')}*:`}
                    value={newItem.LABEL}
                    onChange={(e) => setField('LABEL', e.target.value)}
                />
                <Input
                    label={`${t('Järjekord')}:`}
                    value={newItem.ORDER_NO}
                    onChange={(e) => setField('ORDER_NO', e.target.value)}
                    type='number'
                />
                <SelectInput
                    label={`${t('Tüüp')}:`}
                    options={MachineCodes}
                    onChange={(option) => setField('CODE', option.value)}
                    getOptionLabel={(option) => t(option.label)}
                    value={MachineCodes.find((machine) => machine.value === newItem.CODE)}
                />
                <SelectInput
                    label={`${t('Asukoht')}:`}
                    options={locations}
                    onChange={(option) => setField('LOCATION_ID', option.ID)}
                    value={locations.find((location) => location.ID === newItem.LOCATION_ID)}
                    getOptionLabel={(option) => option.LABEL}
                    getOptionValue={(option) => option.ID}
                />
                <Input
                    label={`${t('Jõudlus')}:`}
                    value={newItem.PERFORMANCE}
                    onChange={(e) => setField('PERFORMANCE', e.target.value)}
                    type='number'
                />
                <Input
                    label={`${t('Min. Mõõt')}:`}
                    value={newItem.MIN_SIZE}
                    onChange={(e) => setField('MIN_SIZE', e.target.value)}
                    type='number'
                />
                <Input
                    label={`${t('Max. Mõõt')}:`}
                    value={newItem.MAX_SIZE}
                    onChange={(e) => setField('MAX_SIZE', e.target.value)}
                    type='number'
                />
                <Button
                    label={t('Lisa masin')}
                    onClick={addItem}
                    disabled={!validateFields()}
                />
            </Modal>
            <Modal
                title={t('Muuda masina tüüpi')}
                show={showEditModal}
                close={() => setShowEditModal(false)}
            >
                <SelectInput
                    label={`${t('Tüüp')}:`}
                    options={MachineCodes}
                    onChange={(option) => setSelectedRow({
                        ...selectedRow,
                        CODE: option?.value,
                    })}
                    getOptionLabel={(option) => t(option?.label)}
                    value={MachineCodes.find((machine) => machine.value === selectedRow?.CODE)}
                />
                <Button
                    label={t('Salvesta')}
                    onClick={() => {
                        updateProperty(selectedRow, 'CODE', selectedRow?.CODE)
                        setShowEditModal(false)
                        tableRef.current?.refresh()
                    }}
                />
            </Modal>
        </div>
    )
}

export default MachinesScreen