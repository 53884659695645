import React, { useState, useMemo, useCallback } from 'react'
import { addDays, eachDayOfInterval, format, startOfWeek, startOfDay, endOfDay, endOfWeek } from 'date-fns'
import Button from './Button'
import Modal from './Modal'
import AdditionalModal from './AdditionalModal'
import IconButton from './IconButton'
import ChevronIcon from './icons/chevron'
import { useMutation, useQuery } from '@apollo/client'
import { CreateMachineSchedule, UpdateMachineSchedule, CopyMachineSchedules, RemoveMachineScheduleTime } from '../graphql/mutations'
import { GetMachineSchedules } from '../graphql/queries'
import { useNotification } from '../providers/Notification'
import DatePicker from 'react-datepicker'
import CopyIcon from './icons/copy'
import { useTranslation } from 'react-i18next'
import ActivityIndicator from './ActivityIndicator'


const Weekdays = [
    { label: 'Pühapäev', value: 0 },
    { label: 'Esmaspäev', value: 1 },
    { label: 'Teisipäev', value: 2 },
    { label: 'Kolmapäev', value: 3 },
    { label: 'Neljapäev', value: 4 },
    { label: 'Reede', value: 5 },
    { label: 'Laupäev', value: 6 },
]

const MachineSchedule = ({ machineData }) => {
    const { dispatch } = useNotification()
    const { t } = useTranslation()
    // States
    const [currentDay, setCurrentDay] = useState(new Date())
    const [showModal, setShowModal] = useState(false)
    const [copyRange, setCopyRange] = useState([null, null])
    const [showAdditionalModal, setShowAdditionalModal] = useState(false)
    const [scheduleState, setScheduleState] = useState({
        isEdit: false,
        day: null,
        startTime: null,
        endTime: null,
        editingSchedule: null
    })
    const [showCalendar, setShowCalendar] = useState(false)

    // Mutations
    const [createSchedule] = useMutation(CreateMachineSchedule)
    const [copySchedules] = useMutation(CopyMachineSchedules)
    const [updateScheduleTime] = useMutation(UpdateMachineSchedule)
    const [removeScheduleTime] = useMutation(RemoveMachineScheduleTime)

    // Week data including week start date, end date and all the days within that week
    const weekDetails = useMemo(() => {
        const startOfWeekDate = startOfWeek(currentDay, { weekStartsOn: 1 })
        const days = eachDayOfInterval({
            start: startOfWeekDate,
            end: addDays(startOfWeekDate, 6),
        })

        const endOfWeekDate = addDays(startOfWeekDate, 6)
        endOfWeekDate.setHours(23, 59, 59)

        return {
            startOfWeekDate,
            days,
            endOfWeekDate,
        }
    }, [currentDay])

    // Weekly Schedule Query
    const { data: calendarData, loading, refetch } = useQuery(GetMachineSchedules, {
        variables: {
            machineId: machineData?.ID,
            startDate: weekDetails.startOfWeekDate?.toISOString(),
            endDate: weekDetails.endOfWeekDate?.toISOString()
        },
        fetchPolicy: 'no-cache',
        skip: !machineData || !weekDetails.startOfWeekDate || !weekDetails.endOfWeekDate,
        onError: (err) => {
            console.log("error", err)
        }
    })

    const handleCreate = () => {
        const { day, startTime, endTime, isEdit, editingSchedule } = scheduleState

        if (!day || !startTime || !endTime || startTime > endTime) {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: 'Salvestamine ebaõnnestus. Kontrolli väljasid'
                }
            })
            return
        }

        const startDateTime = new Date(day)
        startDateTime.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0)

        const endDateTime = new Date(day)
        endDateTime.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0)

        const scheduleData = {
            MACHINE_ID: parseInt(machineData.ID),
            FROM_DATE: weekDetails.startOfWeekDate?.toISOString(),
            TO_DATE: weekDetails.endOfWeekDate?.toISOString(),
            scheduleTimes: [{
                startTime: startDateTime.toISOString(),
                endTime: endDateTime.toISOString(),
            }]
        }

        const mutation = isEdit ?
            updateScheduleTime({
                variables: {
                    id: parseInt(editingSchedule.ID),
                    data: scheduleData
                }
            }) :
            createSchedule({
                variables: { 
                    data: scheduleData 
                }
            })

        mutation
            .then((resp) => {
                if (resp?.data?.createMachineSchedule === 'Success' || resp?.data?.updateMachineSchedule === 'Success') {
                    refetch()
                    dispatch({
                        type: 'ADD',
                        payload: {
                            type: 'success',
                            content: isEdit ? 'Graafik edukalt uuendatud' : 'Graafik edukalt loodud'
                        }
                    })
                    setShowModal(false)
                    setScheduleState({
                        isEdit: false,
                        day: null,
                        startTime: null,
                        endTime: null,
                        editingSchedule: null
                    })
                }
                else {
                    dispatch({
                        type: 'ADD',
                        payload: {
                            type: 'error',
                            content: isEdit ? 'Graafiku uuendamisel tekkis viga' : 'Graafiku loomisel tekkis viga'
                        }
                    })
                }
            })
            .catch((error) => {
                console.error('Error with schedule:', error)
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: isEdit ? 'Graafiku uuendamisel tekkis viga' : 'Graafiku loomisel tekkis viga'
                    }
                })
            })
    }


    const openScheduleModal = useCallback((isEdit = false, scheduleOrDay = null) => {
        const scheduleStartTime = scheduleOrDay?.START_TIME ? new Date(scheduleOrDay.START_TIME) : null
        const scheduleEndTime = scheduleOrDay?.END_TIME ? new Date(scheduleOrDay.END_TIME) : null

        setScheduleState({
            isEdit,
            day: scheduleStartTime || scheduleOrDay,
            startTime: scheduleStartTime,
            endTime: scheduleEndTime,
            editingSchedule: isEdit ? scheduleOrDay : null
        })
        setShowModal(true)
    }, [])



    const renderModalContent = () => {
        const { day, startTime, endTime, isEdit, editingSchedule } = scheduleState

        const toMinutes = (time) => time.getHours() * 60 + time.getMinutes()

        const isWithinDay = (start, end, dayStart, dayEnd) =>
            (start >= dayStart && start <= dayEnd) ||
            (end >= dayStart && end <= dayEnd) ||
            (start <= dayStart && end >= dayEnd)


        // Find schedules for the selected day
        const daySchedules = calendarData?.getMachineSchedules?.flatMap((schedule) =>
            schedule.scheduleTimes.filter((time) => {
                const scheduleStartDate = new Date(time.START_TIME)
                const scheduleEndDate = new Date(time.END_TIME)
                return isWithinDay(scheduleStartDate, scheduleEndDate, startOfDay(day), endOfDay(day))
            })
        ) || []

        // Create a list of blocked time ranges
        const blockedTimeRanges = daySchedules
            .filter((schedule) => !isEdit || schedule.ID !== editingSchedule?.ID)
            .map((schedule) => ({
                start: toMinutes(new Date(schedule.START_TIME)),
                end: toMinutes(new Date(schedule.END_TIME)),
            }))


        const isTimeBlocked = (time) => {
            const timeInMinutes = toMinutes(time)
            return blockedTimeRanges.some((range) => timeInMinutes >= range.start && timeInMinutes < range.end)
        }


        const generateAvailableTimes = () => {
            const times = []
            for (let hour = 0; hour < 24; hour++) {
                for (let minute = 0; minute < 60; minute += 15) {
                    const time = new Date(0, 0, 0, hour, minute)
                    times.push(time)
                }
            }
            times.push(new Date(0, 0, 0, 23, 59))
            return times
        }

        const allTimes = generateAvailableTimes()

        const getAvailableEndTimes = () => {
            if (!startTime) return []

            const startTimeInMinutes = toMinutes(startTime)

            return allTimes.filter((time) => {
                const timeInMinutes = toMinutes(time)
                return (
                    timeInMinutes > startTimeInMinutes &&
                    !blockedTimeRanges.some((range) =>
                        startTimeInMinutes < range.end && timeInMinutes > range.start
                    )
                )
            })
        }

        return (
            <div className="modal-content">
                <h2>
                    {isEdit
                        ? `Graafiku muutmine ${day ? format(day, 'dd.MM.yyyy') : ''}`
                        : `Graafiku lisamine ${day ? format(day, 'dd.MM.yyyy') : ''}`
                    }
                </h2>
                <DatePicker
                    selected={startTime}
                    onChange={(time) => {
                        setScheduleState(prev => ({
                            ...prev,
                            startTime: time,
                            endTime: isEdit ? prev.endTime : null,
                        }))
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Algusaeg"
                    dateFormat="HH:mm"
                    locale="et"
                    placeholderText={t("Vali algusaeg")}
                    includeTimes={allTimes.filter(time => !isTimeBlocked(time))}
                    minTime={new Date(0, 0, 0, 0, 0)}
                    maxTime={new Date(0, 0, 0, 23, 59)}
                    wrapperClassName="input-wrapper"
                />
                <DatePicker
                    selected={endTime}
                    onChange={(time) => setScheduleState(prev => ({
                        ...prev,
                        endTime: time
                    }))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Lõpuaeg"
                    dateFormat="HH:mm"
                    locale="et"
                    placeholderText={t("Vali lõpuaeg")}
                    includeTimes={getAvailableEndTimes()}
                    minTime={startTime || new Date(0, 0, 0, 0, 0)}
                    maxTime={new Date(0, 0, 0, 23, 59)}
                    disabled={!startTime}
                    wrapperClassName="input-wrapper"
                />

                <Button
                    onClick={handleCreate}
                    label={isEdit ? t("Salvesta muudatused") : t("Lisa Graafik")}
                    disabled={!startTime || !endTime}
                />
            </div>
        )
    }

    const onDateRangeChange = (dates) => {
        const [start, end] = dates
    
        // Helper function to get the Monday of the week
        const getMonday = (date) => {
            const day = date.getDay() // Sunday - 0, Monday - 1
            const diff = (day === 0 ? -6 : 1) - day
            const monday = new Date(date)
            monday.setDate(monday.getDate() + diff)
            monday.setHours(0, 0, 0, 0)
            return monday
        }
    
        // Helper function to get the Sunday of the week
        const getSunday = (date) => {
            const day = date.getDay()
            const diff = day === 0 ? 0 : 7 - day
            const sunday = new Date(date)
            sunday.setDate(sunday.getDate() + diff)
            sunday.setHours(23, 59, 59, 999)
            return sunday
        }

        if (!start && !end) {
            setCopyRange([null, null])
            return
        }
        const rangeStart = start ? getMonday(new Date(start)) : null
        const rangeEnd = end ? getSunday(new Date(end)) : null

        setCopyRange([rangeStart, rangeEnd])
    }

    const handleCopy = () => {
        const [rangeStart, rangeEnd] = copyRange

        if (!rangeStart || !rangeEnd || rangeStart > rangeEnd) {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: 'Palun vali korrektne kuupäevavahemik',
                },
            })
            return
        }

        const schedulesData = {
            MACHINE_ID: parseInt(machineData.ID),
            startOfWeek: weekDetails.startOfWeekDate?.toISOString(),
            endOfWeek: weekDetails.endOfWeekDate?.toISOString(),
            selectedRange: {
                startDate: rangeStart.toISOString(),
                endDate: rangeEnd.toISOString(),
            },
        }

        copySchedules({
            variables: { data: schedulesData },
        })
            .then(() => {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: 'Graafikud edukalt kopeeritud',
                    },
                })
                refetch()
                setShowAdditionalModal(false)
            })
            .catch((error) => {
                console.error('Error copying schedules:', error)
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'error',
                        content: 'Graafiku kopeerimisel tekkis viga',
                    },
                })
            })
    }


    const handleRemoveTime = async (id) => {
        try {
            const { data } = await removeScheduleTime({
                variables: {
                    id: parseInt(id),
                },
            })

            if (data?.removeMachineScheduleTime === 'Success') {
                dispatch({
                    type: 'ADD',
                    payload: {
                        type: 'success',
                        content: 'Graafiku kustutamine edukas',
                    },
                })
                refetch()
            }

        } catch (err) {
            console.log('handleRemoveTime', err)
        }
    }

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar)
    }

    const handleWeekChange = (date) => {
        if (date) {
            setCurrentDay(date)
            setShowCalendar(false)
        }
    }

    return (
        <div className="scheduler">
            <div className='inner-title--actions'>
                <IconButton
                    label={t('Kopeeri käesoleva nädala graafik')}
                    icon={<CopyIcon />}
                    onClick={() => setShowAdditionalModal(true)}
                />
            </div>

            <AdditionalModal
                className="scheduler-additional-modal"
                show={showAdditionalModal}
                title={t(`Graafiku kopeerimine`)}
                close={() => setShowAdditionalModal(false)}
            >
                <p>{t(`Lisa vahemik, kuhu hetkel valitud nädala graafik (${format(weekDetails.startOfWeekDate, 'dd.MM.yyyy')} - ${format(weekDetails.endOfWeekDate, 'dd.MM.yyyy')}) kopeeritakse`)}</p>

                <div className='date-picker-schedule'>
                    <DatePicker
                        selected={copyRange[0]}
                        onChange={onDateRangeChange}
                        startDate={copyRange[0]}
                        endDate={copyRange[1]}
                        selectsRange
                        inline
                        locale='et'
                    />
                </div>

                <Button 
                    label={t("Kopeeri käesoleva nädala graafik")}
                    onClick={handleCopy}>
                </Button>
            </AdditionalModal>

            <Modal 
                className="scheduler-modal"
                show={showModal} close={() => {
                    setShowModal(false)
                    setScheduleState({
                        isEdit: false,
                        day: null,
                        startTime: null,
                        endTime: null,
                        editingSchedule: null
                    })
                }}
            >
                {renderModalContent()}
            </Modal>


            {/* Navigation buttons for selecting previous and next week */}
            <div className="schedule-week-container">
                <div className='schedule-week-container--buttons'>
                    <IconButton
                        onClick={() => setCurrentDay(prevDay => addDays(prevDay, -7))}
                        icon={<ChevronIcon />}
                        label={t("Eelmine nädal")}
                    />
                    <IconButton
                        className="forward-button"
                        onClick={() => setCurrentDay(prevDay => addDays(prevDay, 7))}
                        icon={<ChevronIcon />}
                        label={ t("Järgmine nädal")}
                    />
                </div>
                <div className='schedule-calendar'>
                    <div className='schedule-calendar--week' onClick={toggleCalendar}>
                        <span>
                            {format(weekDetails.startOfWeekDate, 'dd.MM.yyyy')} - {format(weekDetails.endOfWeekDate, 'dd.MM.yyyy')}
                        </span>
                    </div>

                    {showCalendar && (
                        <div className='schedule-calendar--tooltip'>
                            <DatePicker
                                selected={currentDay}
                                onChange={handleWeekChange}
                                inline
                                dateFormat="dd.MM.yyyy"
                                locale="et"
                            />
                        </div>
                    )}
                </div>
            </div>

            {loading ? (
                <ActivityIndicator />
            ) : (
                <div className="calendar">
                    {weekDetails.days.map((day) => {
                        const today = new Date()
                        today.setHours(0, 0, 0, 0)
                        const isTodayFlag = day.toDateString() === today.toDateString()

                        return (
                            <div key={day.toISOString()} className={`day ${isTodayFlag ? 'today' : ''}`}>
                                <h3>{Weekdays.find((weekday) => weekday.value === day.getDay()).label}</h3>
                                <p>{format(startOfDay(day), 'dd.MM.yyyy')}</p>
                                <Button onClick={() => openScheduleModal(false, day)} label={"Lisa Graafik"} />
                                <div className="schedules">
                                    {calendarData?.getMachineSchedules
                                        ?.flatMap((schedule) =>
                                            schedule.scheduleTimes.filter((time) => {
                                                const timeStart = new Date(time.START_TIME)
                                                const timeEnd = new Date(time.END_TIME)
                                                const dayStart = startOfDay(day)
                                                const dayEnd = endOfDay(day)

                                                return (
                                                    (timeStart >= dayStart && timeStart <= dayEnd) ||
                                                    (timeEnd >= dayStart && timeEnd <= dayEnd) ||
                                                    (timeStart <= dayStart && timeEnd >= dayEnd)
                                                )
                                            })
                                        )
                                        ?.map((schedule) => (
                                            <div key={schedule.ID} className="schedule">
                                                <div
                                                    className="close modal-close"
                                                    onClick={() => handleRemoveTime(schedule.ID)}
                                                ></div>                                                
                                                <h4>{format(new Date(schedule.START_TIME), 'HH:mm')} - {format(new Date(schedule.END_TIME), 'HH:mm')}</h4>
                                                <Button
                                                    onClick={() => openScheduleModal(true, schedule)}
                                                    label={t("Muuda")}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default MachineSchedule