import React from 'react'
import './style.scss'
import { useQuery } from '@apollo/client'
import { GetMachine } from '../../graphql/queries'
import { useTranslation } from 'react-i18next'

import { useParams } from 'react-router-dom'
import MachineSchedule from '../../components/MachineScheduleCalendar'

const MachineScreen = () => {
    const { t } = useTranslation()
    const { id } = useParams()

    const { data, loading } = useQuery(GetMachine, {
        variables: {
            id: parseInt(id)
        },
        fetchPolicy: 'no-cache',
    })

    const machineData = data?.getMachine
        
    if (loading) return <div>Loading...</div>

    return (
        <>
            {machineData && (
                <div className='machines-screen'>
                    <div className='inner'>
                        <div className='inner-title'>
                            <h2>{t('Masina ') + machineData?.LABEL + ' (' + t(machineData?.location?.LABEL) + ') graafik'}</h2>
                            <div className='inner-title--actions'>
                            </div>
                        </div>
                        <MachineSchedule machineData={machineData} />
                    </div>
                </div>
            )}
        </>
    )
}

export default MachineScreen