import React, { useEffect, useMemo, useState } from 'react'
import Input from './Input'
import { getMaterialNuut, getMaterialSize } from '../util/helpers'
import { useMutation, useQuery } from '@apollo/client'
import { UpdatePlan, SplitPlan } from '../graphql/mutations'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import Modal from './Modal'
import SelectInput from './SelectInput'
import ConfirmModal from './ConfirmModal'
import Textarea from './Textarea'
import { GetBuffer, GetProduction } from '../graphql/queries'
import BlueprintModal from './BlueprintModal'
import { HaltReasons } from '../util/const'

const OperatorMachineProduct = ({
    data,
    refetchData,
}) => {

    const { t } = useTranslation()

    const [item, setItem] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [updatePlan] = useMutation(UpdatePlan)
    const [splitPlan] = useMutation(SplitPlan)
    const [confirmSplitModalOpen, setConfirmSplitModalOpen] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const [prodData, setProdData] = useState()

    useQuery(item?.production ? GetProduction : GetBuffer, {
        skip: !item?.production && !item?.buffer,
        variables: {
            id: parseInt(item?.production?.ID || item?.buffer?.ID),
        },
        fetchPolicy: 'no-cache',
        onCompleted: async (res) => {
            console.log('got prod data')
            setProdData(item?.production ? res?.getProduction : res?.getBuffer)
        },
    })

    useEffect(() => {
        setItem(data)
    }, [])

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })
    }

    const handleStart = () => {
        const date = new Date()

        setField('STARTED', date)
        savePlan({
            STARTED: date,
        })
    }

    const handleFinish = () => {
        const date = new Date()

        setField('COMPLETED', date)
        setConfirmModalOpen(false)

        savePlan({
            COMPLETED: date,
            PRODUCT_IN: parseInt(item.PRODUCT_IN),
            PRODUCT_OUT: parseInt(item.PRODUCT_OUT),
            PRODUCT_WASTE: item.PRODUCT_WASTE ? parseInt(item.PRODUCT_WASTE) : undefined,
            OPERATOR_COMMENT: item.OPERATOR_COMMENT || undefined,
            PLANNED_STARTDATE: item.PLANNED_STARTDATE ? item.PLANNED_STARTDATE : undefined
        })
    }

    const handleConfigurationStart = () => {
        const date = new Date()

        setField('CONFIGURATION_STARTED', date)
        savePlan({
            CONFIGURATION_STARTED: date,
        })
    }

    const savePlan = async (saveFields) => {
        if (!item && !saveFields) return

        const res = await updatePlan({
            variables: {
                id: parseInt(data?.ID),
                data: saveFields || {
                    STARTED: item.STARTED ? new Date(item.STARTED) : undefined,
                    COMPLETED: item.COMPLETED ? new Date(item.COMPLETED) : undefined,
                    CONFIGURATION_STARTED: item.CONFIGURATION_STARTED ? new Date(item.CONFIGURATION_STARTED) : undefined,
                    HALTED: item.HALTED ? new Date(item.HALTED) : undefined,
                    HALTED_REASON: item.HALTED_REASON || undefined,
                    HALTED_COMMENT: item.HALTED_COMMENT || undefined,
                    OPERATOR_COMMENT: item.OPERATOR_COMMENT || undefined,
                    PRODUCT_IN: item.PRODUCT_IN ? parseInt(item.PRODUCT_IN) : undefined,
                    PRODUCT_OUT: item.PRODUCT_OUT ? parseInt(item.PRODUCT_OUT) : undefined,
                    PRODUCT_WASTE: item.PRODUCT_WASTE ? parseInt(item.PRODUCT_WASTE) : undefined,
                },
                date: item.PLANNED_STARTDATE ? item.PLANNED_STARTDATE : undefined
            },
        })
        if (!res || res?.data?.updatePlan.status !== 200) 
            return
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        savePlan()
    }

    const toggleStop = () => {
        if (!item?.HALTED) {
            const date = new Date()

            setField('HALTED', date)
            setModalOpen(true)
            savePlan({
                HALTED: date,
            })
            return
        }

        setField('HALTED', null)
        savePlan({
            HALTED: null,
        })
    }

    const handleProcessed = async (processedAmount) => {
        try {
            const res = await splitPlan({
                variables: {
                    planId: item.ID,
                    processedAmount: parseFloat(processedAmount),
                },
            })
            if (res || res?.data?.splitPlan.status == 200) {
                refetchData()
            }
            setConfirmSplitModalOpen(false)
        } catch (err) {
            console.error(err)
        }
    }

    const renderInfo = useMemo(() => {
        if (!prodData) return <></>

        return (
            <div className='operator-machine-product--info'>
                {
                    item?.STARTED ?
                        <div className='operator-machine-product--actions'>
                            <Button
                                label={t('Töö valmis')}
                                onClick={() => setConfirmModalOpen(true)}
                                disabled={item?.HALTED || !item?.PRODUCT_IN || !item?.PRODUCT_OUT || item?.COMPLETED}
                            />
                            <Button
                                className={item?.HALTED ? `btn-resume` : `btn-cancel`}
                                label={item?.HALTED ? t('Jätka tööga') : t('Seiska töö')}
                                onClick={toggleStop}
                                disabled={item?.COMPLETED}
                            />
                        </div>
                        :
                        <div className='operator-machine-product--actions'>
                            {
                                item.CONFIGURATION_STARTED ?
                                    <Button
                                        label={t('Alusta tööga')}
                                        onClick={handleStart}
                                    />
                                    :
                                    <Button
                                        label={t('Alusta seadistamist')}
                                        onClick={handleConfigurationStart}
                                    />
                            }
                        </div>
                }
                <div className='box-fields'>
                    <div className='field-row'>
                        <div className='field-col' style={{ backgroundColor: item?.COMPLETED ? '#01a045' : 'transparent' }}>
                            <div className='box-fields-item'>
                                <span className='box-fields-item--label'>{t('Rida number')}</span>
                                <span className='box-fields-item--value'>{prodData.ORDERNO}</span>
                            </div>
                            <div className='box-fields-item'>
                                <span className='box-fields-item--label'>{t('Kogus')}</span>
                                <span className='box-fields-item--value'>{prodData.KOGUS}</span>
                            </div>
                            <div className='box-fields-item'>
                                <span className='box-fields-item--label'>{t('Tüüp')}</span>
                                <span className='box-fields-item--value'>{prodData.BOX}</span>
                            </div>
                            <div className='box-fields-item'>
                                <span className='box-fields-item--label'>{t('Trükk')}</span>
                                <span className='box-fields-item--value'>{prodData.TRYKK}</span>
                            </div>
                            <div className='box-fields-item'>
                                <span className='box-fields-item--label'>{t('Materjal')}</span>
                                <span className='box-fields-item--value'>{prodData.MATERIAL}</span>
                            </div>
                            <div className='box-fields-item'>
                                <span className='box-fields-item--label'>{t('FSC')}</span>
                                <span className='box-fields-item--value'>{prodData.FSC}</span>
                            </div>
                            <div className='box-fields-item'>
                                <span className='box-fields-item--label'>{t('Mõõt')}</span>
                                <span className='box-fields-item--value'>{prodData.MOOT}</span>
                            </div>
                            <div className='box-fields-item'>
                                <span className='box-fields-item--label'>{t('Materjali suurus')}</span>
                                <span className='box-fields-item--value'>{getMaterialSize(prodData)}</span>
                            </div>
                            <div className='box-fields-item'>
                                <span className='box-fields-item--label'>{t('Nuudid')}</span>
                                <span className='box-fields-item--value'>{getMaterialNuut(prodData)}</span>
                            </div>
                            <div className='box-fields-item'>
                                <span className='box-fields-item--label'>{t('Toote kood')}</span>
                                <span className='box-fields-item--value'>{prodData.PRODUCTION_CODE}</span>
                            </div>
                            <BlueprintModal
                                orderRowId={prodData?.ORDER_ROW_ID}
                                productionCode={prodData?.PRODUCTION_CODE}
                                production={prodData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }, [prodData, item])

    if (!item) return <></>

    return (
        <div className='operator-machine-product' key={`operator-machine-product-${item.ID}`}>
            {renderInfo}
            <div className='operator-machine-product--work'>
                {
                    item.STARTED ?
                        <>
                            <Input
                                label={t('Sisse')}
                                value={item?.PRODUCT_IN}
                                onChange={(e) => setField('PRODUCT_IN', e.target.value)}
                                suffix={'tk'}
                                disabled={item?.HALTED || item?.COMPLETED}
                            />
                            <Input
                                label={t('Välja')}
                                value={item?.PRODUCT_OUT}
                                onChange={(e) => setField('PRODUCT_OUT', e.target.value)}
                                suffix={'tk'}
                                disabled={item?.HALTED || item?.COMPLETED}
                            />
                            <Input
                                label={t('Praak')}
                                value={item?.PRODUCT_WASTE}
                                onChange={(e) => setField('PRODUCT_WASTE', e.target.value)}
                                suffix={'tk'}
                                disabled={item?.HALTED || item?.COMPLETED}
                            />
                            <Textarea
                                label={t('Töö kommentaar')}
                                onChange={(e) => setField('OPERATOR_COMMENT', e.target.value)}
                                value={item?.OPERATOR_COMMENT}
                                disabled={item?.HALTED || item?.COMPLETED}
                            />
                            <Button
                                label={t('Salvesta')}
                                onClick={savePlan}
                                disabled={item?.COMPLETED}
                            />
                            
                            <Input
                                label={
                                    data?.PROCESSED
                                        ? t('Töödeldud kogus')
                                        : `${t('Töödeldud kogus')} (${t('Alles jäänud kogus')} - ${data?.REMAINING_AMOUNT ?? prodData?.KOGUS ?? 0})`
                                }
                                value={data?.PROCESSED ? data.PROCESSED : inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                type="number"
                                disabled={!!data?.PROCESSED}
                            />

                            <Button
                                label={data?.PROCESSED ? t('Töö on poolitatud') : t('Poolita töö')}
                                onClick={() => setConfirmSplitModalOpen(true)}
                                disabled={
                                    !!data?.PROCESSED || // Disable if "Plan already split"
                                    !inputValue || // Disable if no input added
                                    (data?.REMAINING_AMOUNT ? inputValue >= data?.REMAINING_AMOUNT : false) || // Disable if input value exceeds remaining amount
                                    inputValue >= prodData?.KOGUS // Disable if input value exceeds production quantity
                                }
                            />
                            <ConfirmModal
                                show={confirmSplitModalOpen}
                                confirmLabel={t('Poolita Töö')}
                                close={() => setConfirmSplitModalOpen(false)}
                                onConfirm={() => handleProcessed(inputValue)}
                                title={t('Kas tahad tööd poolitada?')}
                            ></ConfirmModal>
                        </>
                        :
                        <></>
                }
            </div>
            <div className='operator-machine-product--comments'>
                <h3>{ 'Planeerija kommentaar' }</h3>
                <p>{item.COMMENT || '-'}</p>
            </div>

            <Modal
                show={modalOpen}
                close={handleCloseModal}
                className='operator-machine-product--modal'
            >
                <SelectInput
                    label={'Põhjus'}
                    options={HaltReasons}
                    value={HaltReasons.find(i => i.value === item?.HALTED_REASON)}
                    onChange={(option) => setField('HALTED_REASON', option?.value)}
                />
                <Textarea
                    label={t('Kommentaar')}
                    value={item?.HALTED_COMMENT}
                    onChange={(e) => setField('HALTED_COMMENT', e.target.value)}
                />

                <Button
                    label={t('Sulge')}
                    onClick={handleCloseModal}
                />
            </Modal>
            <ConfirmModal
                show={confirmModalOpen}
                confirmLabel={t('Töö valmis')}
                close={() => setConfirmModalOpen(false)}
                onConfirm={handleFinish}
                title={t('Kas töö sai valmis?')}
            >
            </ConfirmModal>
        </div>
    )
}

export default OperatorMachineProduct